<template>
	<div class="accordion-item" v-show="canBeRemoved">
		<h3 class="accordion-header">
			<a class="accordion-button collapsed" :href="`#${facet.id}`" role="button" data-bs-toggle="collapse"
			   aria-expanded="false" aria-controls="{{facet.id}}">
				{{ facet.name }}
			</a>
		</h3>
		<div class="accordion-collapse collapse" :class="{ 'show': isFacetExpanded(facet.filters) }" :id="facet.id">
			<div class="accordion-body">
				<div class="widget widget-links widget-filter">

					<div class="input-group input-group-sm mb-2" v-if="facet.filters.length > 2">
						<input class="widget-filter-search form-control rounded-end" type="text" placeholder="Search"
							   v-model="searchText"><i
						   class="ci-search position-absolute top-50 end-0 translate-middle-y fs-sm me-3"></i>
					</div>

					<ul class="widget-list widget-filter-list " style="max-height: 12rem; overflow-y: scroll;" >

						<li class="widget-list-item widget-filter-item" v-for="filter in filteredFacets">
							<a class="widget-list-link d-flex justify-content-between align-items-center facet-link"
							   v-on:click="facetClicked(facet.type, filter)"><span class="widget-filter-item-text">
									<i class="bi bi-x-circle text-danger" v-if="filter.undo"></i>

									<i class="bi bi-plus-circle" aria-hidden="true" v-else></i>
									&nbsp;
									{{ filter.label }}
									{{ filter.count ? '(' + filter.count + ')' : '' }}
								</span></a>
						</li>

					</ul>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		facet: {
			type: Object,
			required: true
		},
	},
	data() {
		return {
			searchText: '',
			
		};
	},
	watch: {
		facet(newValue, oldValue) {
			this.searchText = '';
		}
	},
	methods: {
		facetClicked(type, filter) {
			this.$emit('facet-clicked', type, filter);
		},
		isFacetExpanded(filters) {
			for (let facet of filters) {
				if (facet.undo != '') {
					return true;
				}
			}
			return false;
		},
	},
	computed: {
		filteredFacets() {
			// Filter facets by search text
			if (this.searchText != '') {
				let filters = this.facet.filters.filter(facet => {
					return facet.label.toLowerCase().includes(this.searchText.toLowerCase());
				});
				return filters;
			}
			return this.facet.filters;
		},
		canBeRemoved(){
			// For manufacturers and categories, we need to check the url. If the url is a manufacturer or category, we can't remove it.
			if (this.facet.type == 'categories') {
				let url = new URL(window.location.href);

				// Check if url has  '-c-'
				if (url.pathname.includes('-c-')) {
					return false;
				}
			}else if (this.facet.type == 'manufacturers') {
				let url = new URL(window.location.href);

				// Check if url has  '-m-'
				if (url.pathname.includes('-m-')) {
					return false;
				}
			}
			return true;
		}

	},

}
</script>

<style lang="scss" scoped>
.facet-link {
	cursor: pointer;
}
</style>