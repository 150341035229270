<template>
	<div class="card mb-grid-gutter">
		<div class="card-body px-4">
			<div class="widget">
				<h3 class="widget-title">Price range</h3>
				<div class="range-slider" ref="rangeSlider" :data-start-min="min" :data-start-max="max" :data-min="min"
					 :data-max="max" :data-step="step" :data-currency="currency">
					<div class="range-slider-ui" ref="rangeSliderUi"></div>
					<div class="d-flex pb-1">
						<div class="w-50 pe-2 me-2">
							<div class="input-group input-group-sm"><span class="input-group-text">$</span>
								<input type="text" class="form-control range-slider-value-min" v-model="minValue"
									   @change="updateValues">
							</div>
						</div>
						<div class="w-50 ps-2">
							<div class="input-group input-group-sm"><span class="input-group-text">$</span>
								<input type="text" class="form-control range-slider-value-max" v-model="maxValue"
									   @change="updateValues">
							</div>
						</div>
					</div>
				</div>

			</div>
			<div class="d-flex justify-content-end mt-2">
				<button type="button" class="btn btn-outline-secondary me-2" @click="resetButtonClicked">Reset</button>
				<button id="priceRangeBtn" type="button" class="btn btn-outline-secondary"
						@click="applyButtonClicked">Apply</button>
			</div>

		</div>

	</div>
	<!-- <div class="range-slider" ref="rangeSlider" :data-start-min="min" :data-start-max="max" :data-min="min" :data-max="max" :data-step="step" :data-currency="currency">
	  <div class="range-slider-ui" ref="rangeSliderUi"></div>
	  <input type="text" class="range-slider-value-min" v-model="minValue" @change="updateValues">
	  <input type="text" class="range-slider-value-max" v-model="maxValue" @change="updateValues">
	</div> -->
</template>
  
<script>
import noUiSlider from 'nouislider';


export default {
	props: {
		min: {
			type: Number,
			default: 0
		},
		max: {
			type: Number,
			default: 18000
		},
		step: {
			type: Number,
			default: 10
		},
		currency: {
			type: String,
			default: '$'
		}
	},
	data() {
		return {
			minValue: this.min,
			maxValue: this.max
		};
	},
	mounted() {
		this.createSlider();
	},
	methods: {
		applyButtonClicked() {
			let price = this.minValue + '-' + this.maxValue;
			console.log('price', price);
			this.$emit('facet-clicked', 'price', {
				'value': price,
				'undo': ''
			});
		},
		resetButtonClicked() {
			this.minValue = this.min;
			this.maxValue = this.max;
			this.$emit('facet-clicked', 'price', {
				'value': '',
				'undo': true
			});
		},
		createSlider() {
			noUiSlider.create(this.$refs.rangeSliderUi, {
				start: [Number(this.min), Number(this.max)],
				connect: true,
				step: this.step,
				pips: { mode: 'count', values: 5 },
				tooltips: true,
				range: {
					'min': Number(this.min),
					'max': Number(this.max)
				},
				format: {
					to: value => `${this.currency}${parseInt(value, 10)}`,
					from: value => Number(value.replace(this.currency, ''))
				}
			});

			this.$refs.rangeSliderUi.noUiSlider.on('update', (values, handle) => {
				this.minValue = parseInt(values[0].replace(/\D/g, ''), 10);
				this.maxValue = parseInt(values[1].replace(/\D/g, ''), 10);
			});
		},
		updateValues() {
			this.$refs.rangeSliderUi.noUiSlider.set([this.minValue, this.maxValue]);
		}
	},
	watch: {
		min(newVal) {
			if (Number.isNaN(newVal)) {
				return;
			}
			if (this.$refs.rangeSliderUi) {
				this.$refs.rangeSliderUi.noUiSlider.updateOptions({
					range: {
						'min': newVal,
						'max': this.max
					}
				});
			}
		},
		max(newVal) {
			if (Number.isNaN(newVal)) {
				return;
			}
			if (this.$refs.rangeSliderUi) {
				this.$refs.rangeSliderUi.noUiSlider.updateOptions({
					range: {
						'min': this.min,
						'max': newVal
					}
				});
			}
		}
	}
}
</script>
  
<style scoped>
.noUi-connect {
	background-color: #000;
}</style>
  