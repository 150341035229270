<template>
	<div class="bg-light shadow-lg rounded-3 px-md-4 py-md-4 px-2 py-2  mt-n5 mb-4">
		<div class="d-flex flex-column  align-items-center justify-content-between  flex-md-row w-100 ">
			<div class="d-flex flex-row align-content-center justify-content-center mb-4 mb-md-0">


				<div class="dropdown me-md-2 ">
					<a class="btn btn-outline-secondary dropdown-toggle" href="#shop-filters" data-bs-toggle="collapse">
						<i class="bi me-2" :class="modifiedData ? 'bi-funnel-fill' : 'bi-funnel'"></i>
						Filters
					</a>
				</div>
				<!-- Checkbox for Stock Available -->
				<div class="form-check form-switch mx-2 my-auto">
					<input class="form-check-input" type="checkbox" id="quickShip" v-model="isQuickShip" checked>
					<label class="form-check-label" for="quickShip">Quick Ship <span class="ms-1 text-info" disabled
							  data-bs-toggle="tooltip" data-bs-placement="top"
							  title="Items verified in-stock to ship in 24-48 hours">
							<i class="bi bi-question-circle"></i>
						</span></label>

				</div>
			</div>
			<div class="d-flex w-100 w-md-auto">
				<vehicle-selector-compact @cleared="clearVehicle" />
			</div>

		</div>
		<!-- Toolbar with expandable filters-->
		<div class="collapse" id="shop-filters">
			<div class="row pt-4">
				<div class="col-lg-6 col-sm-6">

					<!-- Categories-->
					<div class="card mb-grid-gutter">
						<div class="card-body px-4">
							<div class="widget widget-categories">
								<h3 class="widget-title">Filters
									<a v-if="modifiedData" class="ms-1 text-muted small reset-filters"
									   @click="resetButtonClicked">Reset</a>

								</h3>
								<div class="accordion mt-n1" id="shop-categories">

									<!-- Facets -->
									<default-facet v-for="(facet, type) in availableFacets" :key="facet.id"
												   :facet="facet" @facet-clicked="facetClicked" />

								</div>
							</div>
						</div>
					</div>
				</div>

				<div class="col-lg-6 col-sm-6">
					<div class="card mb-grid-gutter">
						<div class="card-body px-4">
							<div class="widget widget-categories">
								<h3 class="widget-title">Universal Parts
								</h3>
								<div class="form-check form-switch mx-2 my-auto mb-2 mb-md-0">
									<input class="form-check-input" type="checkbox" id="universalCheck"
										   v-model="isShowUniversalParts" @change="updateUniversalParts">
									<label class="form-check-label" for="universalCheck">Show Universal Parts</label>
								</div>
							</div>
						</div>
					</div>
					<!-- Price range-->
					<price-facet :max="rules.maxPrice" @facet-clicked="facetClicked" />

				</div>
				<div class="col-lg-4 col-sm-6">




				</div>
			</div>
		</div>
	</div>
</template>

<script>
import DefaultFacet from './Facets/DefaultFacet.vue';
import PriceFacet from './Facets/PriceFacet.vue';
import VehicleSelectorCompact from '../Vehicle/VehicleSelectorCompact.vue';
export default {
	components: {
		DefaultFacet,
		PriceFacet,
		VehicleSelectorCompact
	},
	props: {
		facets: {
			type: Object,
			required: true
		},
		rules: {
			type: Object,
			default: {
				maxPrice: 1000
			}
		},

	},
	data() {
		return {
			modifiedData: false,
			isQuickShip: false,
			isShowUniversalParts: true,
		}
	},
	mounted() {
		// Check if universal parts is set in the URL
		let url = new URL(window.location.href);
		if (url.searchParams.get("universal") == 0) {
			this.isShowUniversalParts = false;
		};
		if (url.searchParams.get("qs") == "true") {
			this.isQuickShip = true;
		};

		this.initTooltips();
	},
	updated() {
		this.initTooltips();
	},
	methods: {
		facetClicked(type, filter) {
			console.log('facetClicked', type, filter);

			if (type == 'categories') {
				type = 'categories_id';
			} else if (type == 'manufacturers') {
				type = 'manufacturers_id';
			}

			if (filter.undo) {
				console.log('undo');
				this.$emit('facet-clicked', type, "");
			} else {
				this.$emit('facet-clicked', type, filter.value);
			}
			this.modifiedData = true;
		},
		clearVehicle() {
			this.isShowUniversalParts = true;
			this.$emit('clear-vehicle');
		},
		updateUniversalParts() {
			this.$emit('facet-clicked', 'universal', Number(this.isShowUniversalParts));
		},

		resetButtonClicked() {
			this.modifiedData = false;
			this.isQuickShip = false;
			this.$emit('facets-reset');
		},
		initTooltips() {
			const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
			tooltipTriggerList.map((tooltipTriggerEl) => new bootstrap.Tooltip(tooltipTriggerEl, { trigger: 'hover' }));
		}

	},
	computed: {

		availableFacets() {
			if (!this.facets) {
				return [];
			}
			// Convert facets to array
			let facets = Object.values(this.facets);
			// Filter out facets with no filters

			return facets.filter(facet => {
				// Filter out price
				if (facet.type == 'price') {
					return false;
				}
				return facet.filters.length > 0;
			});
		},

	},
	watch: {
		isQuickShip() {
			this.$emit('facet-clicked', 'qs', this.isQuickShip);
		},
		// isShowUniversalParts() {
		// 	this.$emit('facet-clicked', 'universal', this.isShowUniversalParts);
		// }
	}
}
</script>

<style scoped>
.reset-filters {
	cursor: pointer;
}
</style>