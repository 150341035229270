<template>
  <div>
    <!-- Category Filters -->
    <product-filters :facets="facets" @facet-clicked="updateFacets" @facets-reset="resetFacets" :rules="rules"
                     @clear-vehicle="clearVehicle" />
    <!-- Manufacturer Description Section -->
    <div v-if="computedShortDescription" class="manufacturer-description">
      <p v-html="computedShortDescription"></p>
    </div>
    <!-- Top 6 Categories Section -->
    <div class="row row-cols-lg-6 row-cols-md-3 row-cols-sm-2 row-cols-1 gy-sm-4 gy-3 pt-sm-3 justify-content-center d-flex">
      <div class="col mb-2" v-for="category in categories" :key="category.categories_id">
        <div class="card category-card shadow-none h-100">
            <a :href="generateCategoryUrl(category)"
               class="card-title product-title fs-base fs-5 py-1 px-1 mb-0 text-center align-middle text-dark"
               style="min-height: 42px;">
              {{ category.manufacturer_category_card_header || this.manufacturerName }}
            </a>
          <a :href="generateCategoryUrl(category)" class="d-block w-100 d-flex justify-content-center category-image">
            <img class="card-img-top" :src="`${category.image_url}`" :alt="category.categories_name" loading="lazy">
          </a>
            <a :href="generateCategoryUrl(category)"
               class="card-title product-title fs-base fs-6 py-1 px-1 mb-0 text-center align-middle text-dark"
               style="min-height: 42px;">
              {{ category.manufacturer_category_card_text || category.categories_name}}
            </a>
        </div>
      </div>
    </div>
    <!-- Custom Divider -->
    <div class="custom-divider"></div>
    <!-- Newest 5 Products Section -->
    <div v-if="newestProducts.length > 0" class="mb-5">
      <div class="newest-products-title">
        <p>Newest Products for {{this.manufacturerName}}</p>
      </div>
      <div class="row pt-3 mx-n2 row-cols-xl-5 row-cols-lg-4 row-cols-md-3 row-cols-1">
        <div class="col px-2 mb-4" v-for="product in newestProducts" :key="product.products_id">
          <product-card 
            :product="product"
            :isWholesale="product.is_wholesale"
            @error="handleProductCardError"
          />
        </div>
      </div>
    </div>
    <div v-else>
      <p>No newest products found.</p>
    </div>
    <!-- Custom Divider -->
    <div class="custom-divider"></div>
    <div class="newest-products-title">
      <p>Other Products for {{this.manufacturerName}}</p>
    </div>
    <!-- Product List Section -->
    <div class="d-flex justify-content-center w-100 justify-content-md-end">
      <div style="width: 250px;">
        <label class="form-label">Sort by:</label>
        <select class="form-select" v-model="sort">
          <option v-for="(value, key) in sortOptions" :key="key" :value="key">{{ value }}</option>
        </select>
      </div>
    </div>

    <div class="row pt-3 mx-n2 row-cols-xl-5 row-cols-lg-4 row-cols-md-3 row-cols-1 " v-show="loading">
      <div class="col px-2 mb-4" v-for="i in 24" :key="i">
        <!-- Loading placeholders -->
        <div class="card product-card shadow-sm" aria-hidden="true">
          <div class="position-relative placeholder-wave">
            <div class="card-img-top placeholder ratio ratio-16x9"></div>
            <i class="ci-image position-absolute top-50 start-50 translate-middle fs-1 text-muted opacity-50"></i>
          </div>
          <div class="card-body py-2">
            <span class="product-meta d-block fs-xs pb-1 placeholder placeholder-sm"></span>
            <h2 class="card-title placeholder-glow">
              <span class="placeholder col-6"></span>
            </h2>
            <a tabindex="-1" class="bg-primary disabled placeholder col-8 placeholder-wave me-4"></a>
          </div>
        </div>
      </div>
    </div>

    <div class="row pt-3 mx-n2 row-cols-xl-5 row-cols-lg-4 row-cols-md-3 row-cols-1 " v-show="!loading">
      <!-- Errors -->
      <div v-if="errorMessage" class="col-12 d-flex justify-content-center">
        <div class="alert alert-danger " role="alert">
          {{ errorMessage }}
        </div>
      </div>

      <div class="col px-2 mb-4" v-for="product in products" :key="product.products_id">
        <product-card :product="product" :isWholesale="isWholesale" />
      </div>
      <div v-if="products.length === 0" class="w-100 d-flex justify-content-center">
        <div class="text-center w-100">
          <h1>No products found</h1>
          <p class="text-muted">Try another search term, or removing a filter.</p>
          <button class="btn btn-primary" @click="resetFacets">Reset Filters</button>
        </div>
      </div>
    </div>
    
    <!-- Pagination -->
    <pagination :pagination="pagination" @page-change="setPage" />
  </div>
  <!-- Manufacturer Description_2 Section -->
  <div v-if="computedLongDescription" class="manufacturer-description-long">
    <p v-html="computedLongDescription"></p>
  </div>
</template>

<script>
import ProductCard from '../Product/ProductCard.vue';
import ProductFilters from './ProductFiltersManufacturerView.vue';
import Pagination from '../common/Pagination.vue';

export default {
  name: 'CategoryList',
  components: {
    ProductCard,
    ProductFilters,
    Pagination
  },
  data() {
    return {
      products: [],
      categories: [],
      selectedFilters: {},
      productsPerPage: 24,
      sort: '5d',
      sortOptions: {
        'rel': 'Relevance',
        'r': 'Recommended',
        '1a': 'Model: A to Z',
        '1d': 'Model: Z to A',
        '3a': 'Name: A to Z',
        '3d': 'Name: Z to A',
        '4a': 'Price: Low to High',
        '4d': 'Price: High to Low',
        '5a': 'Oldest Products',
        '5d': 'Newest Products'
      },
      loading: true,
      page: 1,
      categories_id: 0,
      pagination: {},
      isWholesale: false,
      facets: [],
      preloadData: true,
      isPreloaded: false,
      errorMessage: '',
      isTireCategory: false,
      isManufacturerUrl: false,
      isCategoryUrl: false,
      shortDescription: '',
      longDescription: '',
      manufacturerName: '',
      rules: {},
      newestProducts: [],
      rawData: null,
    };
  },
  created() {
    if (window.vrdata.categories_id) {
      this.selectedFilters.categories_id = window.vrdata.categories_id;
    }
    if (window.vrdata.manufacturers_id) {
      this.selectedFilters.manufacturers_id = window.vrdata.manufacturers_id;
    }
    // Vehicle
    if (window.vrdata.vehicle.model_id != 0) {
      this.selectedFilters.class_9 = window.vrdata.vehicle.model_id;
    }

    // Check if the page is a manufacturer page
    let url = window.location.href;


    if (url.includes('-m-')) {
      this.isManufacturerUrl = true;
    }
    if (url.includes('-c-')) {
      this.isCategoryUrl = true;
    }

    // Get query params from url
    const urlParams = new URLSearchParams(window.location.search);
    // Get the page
    if (urlParams.has('page')) {
      this.page = urlParams.get('page');
    }
    // Get the facets
    for (let [key, value] of urlParams) {
      if (key != 'page') {
        this.selectedFilters[key] = value;
      }
    }
    if (this.preloadData) {
      let data = window.VR_PRELOAD;
      if (data != null) {
        this.isPreloaded = true;
        this.products = data.products;
        this.loading = false;
        this.pagination = data.pagination;
        this.isWholesale = data.is_wholesale;
        this.facets = data.facets;
        this.rules.maxPrice = data.max_price;
        this.isTireCategory = data.is_tire_category;
        this.shortDescription = data.short_description;
        this.sort = data.sort;
        if (data.sort != 'rel') {
          // Remove relevance from sort
          delete this.sortOptions['rel'];
        }
        this.fetchNewestProducts();
      } else {
        this.getProducts();
        this.fetchManufacturerDescriptions();
        this.fetchCategories();
        this.fetchNewestProducts();
      }

    } else {
      this.getProducts();
      this.fetchManufacturerDescriptions();
      this.fetchCategories();
      this.fetchNewestProducts();
    }
    console.log('Created hook: Calling fetchNewestProducts');
    this.fetchNewestProducts();
  },
  methods: {
    generateCategoryUrl(category) {
      return category.canonical_url + `?manufacturers_id=${this.selectedFilters.manufacturers_id}`;
    },
    fetchCategories() {
      // Fetch categories for the manufacturer
      fetch(`/manufacturer-categories/${window.vrdata.manufacturers_id}`)
          .then(response => response.json())
          .then(data => {
            this.categories = data;
          })
          .catch(error => {
            console.error('Error fetching categories:', error);
          });
    },
    fetchManufacturerDescriptions() {
      // Fetch categories for the manufacturer
      fetch(`/manufacturer-descriptions/${window.vrdata.manufacturers_id}`)
          .then(response => response.json())
          .then(data => {
            this.manufacturerName = data[0]?.manufacturers_name;
            this.longDescription = data[0]?.manufacturers_description;
            this.shortDescription = data[0]?.manufacturers_description_2;
          })
          .catch(error => {
            console.error('Error fetching categories:', error);
          });
    },
    getProducts() {
      this.loading = true;
      fetch(`/products_ajax.php?` + new URLSearchParams({
        page: this.page,
        sort: this.sort,
        is_tire_category: this.isTireCategory,
        ...this.selectedFilters
      }))
          .then(response => response.json())
          .then(data => {
            this.loading = false;
            this.products = data.products;
            this.pagination = data.pagination;
            this.isWholesale = data.is_wholesale;
            this.facets = data.facets;
            this.updateUrl();
          })
          .catch(error => {
            console.error('Error loading products:', error);
            this.errorMessage = 'There was an error loading the products. Please try again later.';
            this.loading = false;
          });
    },
    setPage(page) {
      this.page = page;
      this.getProducts();
      this.fetchManufacturerDescriptions();
      this.fetchCategories();
    },
    resetFacets() {
      this.selectedFilters = {};
      if (window.vrdata.categories_id) {
        this.selectedFilters.categories_id = window.vrdata.categories_id;
      }
      if (window.vrdata.manufacturers_id) {
        this.selectedFilters.manufacturers_id = window.vrdata.manufacturers_id;
      }
      this.page = 1;
      this.getProducts();
      this.fetchManufacturerDescriptions();
      this.fetchCategories();
    },
    updateFacets(facet, value) {
      if (value === '') {
        delete this.selectedFilters[facet];
      } else {
        this.selectedFilters[facet] = value;
      }
      this.page = 1;
      this.getProducts();
      this.fetchManufacturerDescriptions();
      this.fetchCategories();
    },
    clearVehicle() {
      // Delete the keys
      delete this.selectedFilters.class_9;
      delete this.selectedFilters.class_8;
      delete this.selectedFilters.class_year;

      this.selectedFilters.universal = 1;

      // Clear window data
      window.vrdata.vehicle = {
        model_id: 0,
        make_id: 0,
        year: 0
      };
      this.setHeroTitle("Products");
      this.updateUrl();
      this.getProducts();
      this.fetchManufacturerDescriptions();
      this.fetchCategories();
    },

    setHeroTitle(title, breadCrumb = "") {
      if (breadCrumb === "") {
        breadCrumb = title;
      }

      let titleEl = document.getElementById('hero-title');
      if (titleEl) {
        titleEl.innerHTML = title;
      }


      let breadcrumbEl = document.querySelector('.hero .breadcrumb .breadcrumb-item.active');
      if (breadcrumbEl) {
        breadcrumbEl.innerHTML = breadCrumb;
      }
    },
    updateUrl() {
      const urlParams = new URLSearchParams();
      for (const [key, value] of Object.entries(this.selectedFilters)) {
        if (key === 'manufacturers_id' && this.isManufacturerUrl) continue;
        if (key === 'categories_id' && this.isCategoryUrl) continue;
        urlParams.set(key, value);
      }
      if (this.page !== 1) urlParams.set('page', this.page);

      if (urlParams.size > 0) {
          window.history.replaceState({}, '', `${location.pathname}?${urlParams}`);
      }
    },
    fetchNewestProducts() {
      const manufacturerId = window.vrdata.manufacturers_id;
      fetch(`/products_ajax.php?sort=5d&new_manufacturer_products=1&manufacturers_id=${manufacturerId}`)
        .then(response => response.json())
        .then(data => {
          this.loading = false;
          this.newestProducts = data.products;
          this.updateUrl();
          this.rawData = JSON.stringify(data);
        })
        .catch(error => {
          console.error('Error fetching newest products:', error);
        });
    },
    formatPrice(price) {
      return `$${parseFloat(price).toFixed(2)}`;
    },
    handleProductCardError(error) {
      console.error('ProductCard error:', error);
    },
    formatProduct(product) {
      return {
        ...product,
        products_price: parseFloat(product.products_price),
        final_price: parseFloat(product.final_price),
        products_quantity: parseInt(product.products_quantity),
        products_weight: parseFloat(product.products_weight),
        products_status: parseInt(product.products_status),
        manufacturers_id: parseInt(product.manufacturers_id),
        products_tax_class_id: parseInt(product.products_tax_class_id),
      };
    },
    formatProductForCard(product) {
      return {
        products_id: product.products_id,
        products_name: product.products_name,
        products_image: product.products_image,
        products_image_lrg: product.products_image_lrg,
        products_price: product.products_price,
        final_price: product.priceData?.finalPrice || product.products_price,
        products_tax_class_id: product.products_tax_class_id,
        products_quantity: product.products_quantity,
        manufacturers_name: product.manufacturers_name,
        products_model: product.products_model,
        products_description: product.products_description,
        products_url: product.products_url,
        in_stock: product.in_stock,
        is_wholesale: product.is_wholesale,
        link: product.products_url,
        priceData: {
          fullPrice: product.priceData?.fullPrice || product.products_price,
          finalPrice: product.priceData?.finalPrice || product.products_price,
          discountPrice: product.priceData?.discountPrice || product.products_price,
          isSale: product.priceData?.isSale || false,
          isCoupon: product.priceData?.isCoupon || false,
          couponCode: product.priceData?.couponCode || null
        },
        low_stock: product.low_stock || false
      };
    },
  },
  watch: {
    sort(newValue) {
      this.page = 1;
      this.getProducts();
      this.fetchManufacturerDescriptions();
      this.fetchCategories();
    },
    page(newValue) {
      this.getProducts();
      this.fetchManufacturerDescriptions();
      this.fetchCategories();
    }
  },
  computed: {
    computedShortDescription() {
      return this.shortDescription
          ? this.shortDescription
          : `Top Categories for ${this.manufacturerName}`;
    },
    computedLongDescription() {
      return this.longDescription
          ? this.longDescription
          : `${this.manufacturerName}`;
    }
  },
  mounted() {
    this.fetchNewestProducts();
  }
};
</script>
<style scoped>
.manufacturer-description-long {
  margin-bottom: 20px;
  text-align: center;
  font-size: 1.2rem;
  color: #333;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.newest-products-title {
  margin-bottom: 20px;
  padding-top: 10px;
  text-align: center;
  font-size: 1.2rem;
  color: #333;
}
.custom-divider {
  width: 100%;
  height: 2px;
  background-color: #ccc; /* Change color to what you prefer */
  margin: 20px 0;
  border-radius: 5px;
  /* You can add a shadow or other styling to make it more prominent */
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
}
</style>

